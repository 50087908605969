<template>
    <br>
<h2 style="width: 75%; margin-left:auto;
margin-right:auto;"><button @click="$router.push('/')" class="btn btn-primary">Current Services</button></h2>
<div class="form-group" style="width: 75%; margin-left:auto;
margin-right:auto;">
                <label>Data Type</label>
                <select class=form-control v-model="filter" @change="updateservices()">
                    <option value="Services" selected>Services</option>
                    <option value="Service Locations">Service Locations</option>
                    <option value="Caskets">Caskets</option>
                    <option value="Colors">Casket Colors</option>
                    <option value="Cemeteries">Cemeteries</option>
                    <option value="Personnel">Personnel</option>
                    <option value="Vehicles">Vehicles</option>
                    <option value="Body Locations">Body Locations</option>
                    <option value="Service Requirements">Service Requirements</option>
                </select>
            </div>
            <br>
<h3 style="width: 75%; margin-left:auto;
margin-right:auto;"><input type='text' class="form-control" placeholder="Search in services" v-model="search"/></h3>
    <div class="card-columns" v-for="service in filteredSearch" :key="service.key">
  <div class="card text-dark card bg-light" style="width: 75%; margin-left:auto;
margin-right:auto; border: 3px solid black; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;">
    <div v-if="service.covid == true" class="card-header covid whitebg"><h2>{{service.name}}</h2></div>
    <div v-if="service.covid != true" class="card-header"><h2>{{service.name}}</h2></div>
    <div class="card-footer"><button @click.prevent="restoreService(service.key)" class="btn btn-primary">Restore</button>
                            &nbsp;
                            <button @click.prevent="deleteService(service.key)" class="btn btn-danger">Delete</button></div>
</div>
<br>
</div>
</template>

<script>
    import { db } from '../firebaseDb';
    export default {
        data() {
            return {
                Services: [],
                FullServices: [],
                tempService: "",
                ServiceLocs: [],
                search: "",
                currentTenant: "",
                service: {},
                added: false,
                doneOnce: false,
                lastSelectedCasket: "",
                filter: "Services",
            }
        },
        computed: {
            filteredSearch:function() {
                return this.FullServices.filter((service) => {
                    var theService = service.name + service.serviceLocCol + service.cemCol;
                    return theService.toLowerCase().match(this.search.toLowerCase());
                })
            }
        },
        
        created() {
            this.currentTenant = this.$tenant;
            this.updateservices();
        },
        methods: {
            updateservices() {
            if(this.filter == "Services") {
                db.collection('services').orderBy("name", "desc").onSnapshot((snapshotChange) => {
                this.FullServices = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.currentTenant) {
                    if(doc.data().deleted == true) {
                    this.FullServices.splice(0, 0, {
                        key: doc.id,
                        name: doc.data().name,
                        date: doc.data().date,
                        deleted: doc.data().deleted,
                    })
                    }
                    }
                });
            })
            }
            
            if(this.filter == "Service Locations") {
                db.collection('serviceLocs').orderBy("name", "desc").onSnapshot((snapshotChange) => {
                this.FullServices = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.currentTenant) {
                    if(doc.data().deleted == true) {
                    this.FullServices.splice(0, 0, {
                        key: doc.id,
                        name: doc.data().name,
                        deleted: doc.data().deleted,
                    })
                    }
                    }
                });
            })
            }
            
            if(this.filter == "Vehicles") {
                db.collection('vehs').orderBy("name", "desc").onSnapshot((snapshotChange) => {
                this.FullServices = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.currentTenant) {
                    if(doc.data().deleted == true) {
                    this.FullServices.splice(0, 0, {
                        key: doc.id,
                        name: doc.data().name,
                        deleted: doc.data().deleted,
                    })
                    }
                    }
                });
            })
            }
            
            if(this.filter == "Personnel") {
                db.collection('perss').orderBy("name", "desc").onSnapshot((snapshotChange) => {
                this.FullServices = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.currentTenant) {
                    if(doc.data().deleted == true) {
                    this.FullServices.splice(0, 0, {
                        key: doc.id,
                        name: doc.data().name,
                        deleted: doc.data().deleted,
                    })
                    }
                    }
                });
            })
            }
            
             if(this.filter == "Cemeteries") {
                db.collection('cems').orderBy("name", "desc").onSnapshot((snapshotChange) => {
                this.FullServices = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.currentTenant) {
                    if(doc.data().deleted == true) {
                    this.FullServices.splice(0, 0, {
                        key: doc.id,
                        name: doc.data().name,
                        deleted: doc.data().deleted,
                    })
                    }
                    }
                });
            })
            }
            
            if(this.filter == "Colors") {
                db.collection('colors').orderBy("name", "desc").onSnapshot((snapshotChange) => {
                this.FullServices = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.currentTenant) {
                    if(doc.data().deleted == true) {
                    this.FullServices.splice(0, 0, {
                        key: doc.id,
                        name: doc.data().name,
                        deleted: doc.data().deleted,
                    })
                    }
                    }
                });
            })
            }
            
            if(this.filter == "Body Locations") {
                db.collection('bls').orderBy("name", "desc").onSnapshot((snapshotChange) => {
                this.FullServices = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.currentTenant) {
                    if(doc.data().deleted == true) {
                    this.FullServices.splice(0, 0, {
                        key: doc.id,
                        name: doc.data().name,
                        deleted: doc.data().deleted,
                    })
                    }
                    }
                });
            })
            }
            
            if(this.filter == "Service Requirements") {
                db.collection('srs').orderBy("name", "desc").onSnapshot((snapshotChange) => {
                this.FullServices = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.currentTenant) {
                    if(doc.data().deleted == true) {
                    this.FullServices.splice(0, 0, {
                        key: doc.id,
                        name: doc.data().name,
                        deleted: doc.data().deleted,
                    })
                    }
                    }
                });
            })
            }
            
            if(this.filter == "Caskets") {
                db.collection('caskets').orderBy("name", "desc").onSnapshot((snapshotChange) => {
                this.FullServices = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.currentTenant) {
                    if(doc.data().deleted == true) {
                    this.FullServices.splice(0, 0, {
                        key: doc.id,
                        name: doc.data().name,
                        deleted: doc.data().deleted,
                    })
                    }
                    }
                });
            })
            }
            },
            details(name) {
                this.tempService = this.FullServices.find(x => x.name === name);
                console.log(this.tempService);
            },
            restoreService(id) {
                let dbRef = db.collection('services').doc(id);
                if(this.filter == "Services") {
                        dbRef = db.collection('services').doc(id);
                        dbRef.get().then((doc) => {
                        this.service = doc.data();
                        this.service.deleted = false;
                    db.collection('services').doc(id)
                .update(this.service).then(() => {
                    console.log("Service successfully updated!");
                    if(this.service.casket !== null && this.service.casket !== "") { 
                    db.collection('caskets').onSnapshot((snapshotChange) => {
                    snapshotChange.forEach((doc) => {
                        if(doc.data().name == this.service.casket && doc.data().tenant == this.currentTenant) {
                            if(this.added == false) {
                                this.lastSelectedCasket = doc.data();
                                console.log(this.service.casket)
                                this.lastSelectedCasket.amount -= 1;
                                console.log(this.lastSelectedCasket.amount)
                                if(this.lastSelectedCasket.amount < 1) {
                                    this.lastSelectedCasket.available = false;
                                }    
                                
                            
                            db.collection('caskets').doc(doc.id)
                            .update(this.lastSelectedCasket).then(() => {
                            console.log("Last Selected successfully updated!");
                        }).catch((error) => {
                            console.log(error);
                        });
                        this.added = true;
                        }
                        }
                    });
                })
                    }
                    this.$router.push('/')
                }).catch((error) => {
                    console.log(error);
                });
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                if(this.filter == "Service Locations") {
                        dbRef = db.collection('serviceLocs').doc(id);
                        dbRef.get().then((doc) => {
                        this.service = doc.data();
                        this.service.deleted = false;
                    db.collection('serviceLocs').doc(id)
                .update(this.service).then(() => {
                    console.log("Service successfully updated!");
                    this.$router.push('/sl')
                }).catch((error) => {
                        console.log(error)
                    })
                })
                }
                
                if(this.filter == "Cemeteries") {
                        dbRef = db.collection('cems').doc(id);
                        dbRef.get().then((doc) => {
                        this.service = doc.data();
                        this.service.deleted = false;
                    db.collection('cems').doc(id)
                .update(this.service).then(() => {
                    console.log("Service successfully updated!");
                    this.$router.push('/cl')
                }).catch((error) => {
                        console.log(error)
                    })
                })
                }
                
                if(this.filter == "Personnel") {
                        dbRef = db.collection('perss').doc(id);
                        dbRef.get().then((doc) => {
                        this.service = doc.data();
                        this.service.deleted = false;
                    db.collection('perss').doc(id)
                .update(this.service).then(() => {
                    console.log("Service successfully updated!");
                    this.$router.push('/pl')
                }).catch((error) => {
                        console.log(error)
                    })
                })
                }
                
                if(this.filter == "Body Locations") {
                        dbRef = db.collection('bls').doc(id);
                        dbRef.get().then((doc) => {
                        this.service = doc.data();
                        this.service.deleted = false;
                    db.collection('bls').doc(id)
                .update(this.service).then(() => {
                    console.log("Service successfully updated!");
                    this.$router.push('/bll')
                }).catch((error) => {
                        console.log(error)
                    })
                })
                }
                
                if(this.filter == "Colors") {
                        dbRef = db.collection('colors').doc(id);
                        dbRef.get().then((doc) => {
                        this.service = doc.data();
                        this.service.deleted = false;
                    db.collection('colors').doc(id)
                .update(this.service).then(() => {
                    console.log("Service successfully updated!");
                    this.$router.push('/colorlist')
                }).catch((error) => {
                        console.log(error)
                    })
                })
                }
                
                if(this.filter == "Service Requirements") {
                        dbRef = db.collection('srs').doc(id);
                        dbRef.get().then((doc) => {
                        this.service = doc.data();
                        this.service.deleted = false;
                    db.collection('srs').doc(id)
                .update(this.service).then(() => {
                    console.log("Service successfully updated!");
                    this.$router.push('/srl')
                }).catch((error) => {
                        console.log(error)
                    })
                })
                }
                
                 if(this.filter == "Vehicles") {
                        dbRef = db.collection('vehs').doc(id);
                        dbRef.get().then((doc) => {
                        this.service = doc.data();
                        this.service.deleted = false;
                    db.collection('vehs').doc(id)
                .update(this.service).then(() => {
                    console.log("Service successfully updated!");
                    this.$router.push('/vl')
                }).catch((error) => {
                        console.log(error)
                    })
                })
                }
                
                if(this.filter == "Caskets") {
                        dbRef = db.collection('caskets').doc(id);
                        dbRef.get().then((doc) => {
                        this.service = doc.data();
                        this.service.deleted = false;
                    db.collection('caskets').doc(id)
                .update(this.service).then(() => {
                    console.log("Service successfully updated!");
                    this.$router.push('/casketlist')
                }).catch((error) => {
                        console.log(error)
                    })
                })
                }
                    
            },
            deleteService(id){
              if (window.confirm("Do you really want to delete? (This service will be gone forever!!!)")) {
                  if(this.filter == "Services") {
                    db.collection("services").doc(id).delete().then(() => {
                        console.log("Service deleted!");
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                  }
                  if(this.filter == "Service Locations") {
                    db.collection("serviceLocs").doc(id).delete().then(() => {
                        console.log("Service deleted!");
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                  }
                  if(this.filter == "Caskets") {
                    db.collection("caskets").doc(id).delete().then(() => {
                        console.log("Service deleted!");
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                  }
              }
            }
        }
    }
</script>

<style>
    .float-container {
    padding: 20px;
}

.float-child {
    float: left;
    padding: 10px;
}  
.covid {
    color: red;
}
</style>